/**
 * 过滤器中的选择样式
 */
enum SelectionFilterType {
  //下拉框的样式
  COMBOBOX = "COMBOBOX",
  //按钮的样式
  BUTTON = "BUTTON",
}

export default SelectionFilterType
